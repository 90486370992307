<template>
  <div>
    <div class="public-margin-bottom">
      <el-button type="primary" size="mini" @click="addMemberTag('kind')"
        >+ 新增分类</el-button
      >
    </div>

    <div
      class="df-row"
      v-for="(kindItem, kindIndex) in kindList"
      :key="kindIndex"
    >
      <div class="kind-name">
        <span>{{ kindItem.KindName }}</span>
        <i
          v-if="kindItem.KindGuid"
          class="el-icon-edit-outline"
          @click="onEditMemberTag('kind', kindItem)"
        ></i>
        <i
          v-if="kindItem.KindGuid"
          class="el-icon-delete"
          @click="deleteMemberTagKind(kindItem)"
        ></i>
      </div>
      <div class="tag-box">
        <el-tag
          v-for="(tagItem, tagIndex) in kindItem.TagList"
          :key="tagIndex"
          closable
          :disable-transitions="false"
          @close="onDeleteMemberTag(tagItem)"
        >
          {{ tagItem.TagName }}
          <i
            class="el-icon-edit"
            @click="onEditMemberTag('tag', tagItem, kindItem)"
          ></i>
        </el-tag>

        <el-button
          class="button-new-tag"
          type="primary"
          size="mini"
          @click="addMemberTag('tag', kindItem)"
          >+ 添加标签</el-button
        >
      </div>
    </div>

    <EditDialog
      ref="editDialog"
      :formData="formData"
      :kindList="kindList"
      :type="type"
      :etitle="etitle"
      @success="getMemberTagSelector"
    >
    </EditDialog>
  </div>
</template>

<script>
import member from "@/api/member.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  components: { EditDialog },

  data() {
    return {
      type: 1,
      kindList: [],
      formData: {},
      tableMaxHeight: "",
      etitle: "",
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    // this.initPageData();
    this.getMemberTagSelector();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - 160;
    },

    // 获取标签分类数据列表
    async getMemberTagSelector() {
      try {
        let { data } = await member.getMemberTagSelector();
        this.kindList = data.KindList;
      } catch (err) {
        console.log(err);
      }
    },

    // 删除分类
    deleteMemberTagKind(event) {
      this.$confirm(`是否删除该‘${event.KindName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let { errcode } = await member.deleteMemberTagKind({
          kind_guid: event.KindGuid,
        });

        if (errcode == 0) {
          this.$message.success("删除成功");
          this.getMemberTagSelector();
        }
      });
    },

    // 打开添加分类、标签对话框
    addMemberTag(text, kindItem = {}) {
      this.type = text == "tag" ? 2 : 1;
      this.etitle = `添加${this.type == 2 ? "标签" : "分类"}`;
      let data =
        this.type == 1
          ? {
              KindName: "",
              Sort: this.kindList[this.kindList.length - 1].KindSort + 1
            }
          : {
              TagGuid: "",
              TagName: "",
              KindGuid: kindItem.KindGuid || "",
            };
      this.$refs.editDialog.onShowDialog(data);
    },

    // 打开修改分类、标签对话框
    onEditMemberTag(text, event = "", kindInfo = "") {
      this.type = text == "tag" ? 2 : 1;
      this.etitle = `${this.type == 2 && event == "" ? "添加" : "修改"}${
        this.type == 2 ? "标签" : "分类"
      }`;
      let data =
        this.type == 1
          ? {
              KindGuid: event.KindGuid || kindInfo.KindGuid || "",
              KindName: event.KindName || kindInfo.KindName || "",
              Sort: event.KindSort || kindInfo.KindSort || "",
            }
          : {
              TagGuid: event.TagGuid,
              TagName: event.TagName,
              KindGuid: event.KindGuid || kindInfo.KindGuid || "",
            };
      this.$refs.editDialog.onShowDialog(data);
    },

    // 删除标签
    onDeleteMemberTag(event) {
      this.$confirm(`是否删除该‘${event.TagName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await member.deleteMembertag({
              tag_guid: event.TagGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.getMemberTagSelector();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.df-row {
  .flex-col;
  align-items: unset;
  .kind-name {
    font-size: 18px;
    i {
      margin-left: 6px;
      font-size: 18px;
      color: #999;
      cursor: pointer;

      &:hover {
        color: #409eff;
      }
    }
  }

  .tag-box {
    .el-tag {
      margin: 10px 10px 0 0px;
    }
    ::v-deep .el-tag {
      font-size: 16px;
      .el-icon-edit {
        font-size: 16px;
        padding: 0 4px;
        cursor: pointer;
      }
    }
    .button-new-tag {
      margin: 10px 10px 0 0px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
  }
}
</style>
